import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import {
  CountriesService,
  KeyValuePair,
} from '@app/shared/services/countries.service';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';
// import holding_creation from '../../schema/holding-creation.json';

@Component({
  selector: 'app-country-input',
  templateUrl: './country-input.component.html',
  styleUrl: './country-input.component.scss',
})
export class CountryInputComponent extends FieldType {
  localizedCountries: KeyValuePair[] = [];
  filteredLocalizedCountries: Observable<KeyValuePair[]> = new Observable();
  filteredLocalizedCountriesSync: KeyValuePair[] = [];

  protected firstChange = true;

  @ViewChild(MatAutocomplete) _auto!: MatAutocomplete;

  constructor(private countriesService: CountriesService) {
    super();
    this.localizedCountries = this.countriesService.getLocalizedCountries();
  }

  ngOnInit() {
    this.field.formControl?.valueChanges.subscribe((value) => {
      // First value change (to initialise the field if model already has a value)
      if (
        this.field.formControl?.pristine &&
        this.firstChange &&
        this.field.formControl?.value
      ) {
        let option = this._auto.options.find((option) => {
          return option.value == this.field.formControl?.value;
        });

        // Update if still using country value and not codes
        if (
          this.field.formControl?.value !==
            this.field.formControl?.value.toUpperCase() &&
          this.field.formControl?.value.length != 2
        ) {
          let countryCode = this.localizedCountries.find(
            (pair) =>
              pair.value.toLowerCase() ===
              this.field.formControl?.value.toLowerCase()
          )?.key;
          if (countryCode) {
            option = this._auto.options.find(
              (option) => option.value == countryCode
            );
            if (option) {
              this.field.formControl?.setValue(option?.value);
            }
          }
        }

        if (option) {
          this.filteredLocalizedCountriesSync = [
            ...this.localizedCountries,
          ].filter((option) => option.key == this.field.formControl?.value);
          const autoComplete = this._auto;
          const key = this.field.formControl?.value;
          setTimeout(function () {
            let option = autoComplete.options.find((option) => {
              return option.value == key;
            });
            option?.select();
          }, 500);
        }
      }
      this.firstChange = false;
      console.log('Value changed: ', value);
      if (
        this.field.formControl?.value !==
          this.field.formControl?.value.toUpperCase() &&
        this.field.formControl?.value.length != 2
      ) {
        this.field.formControl?.setErrors({ incorrectCountry: true });
      } else {
        this.field.formControl?.setErrors(null);
      }
    });
    this.filteredLocalizedCountriesSync = this.localizedCountries;
  }

  onInputChange(event: any) {
    if (event.target.value && event.target.value !== '') {
      this.filteredLocalizedCountriesSync = [...this.localizedCountries].filter(
        (pair: KeyValuePair) =>
          pair.value.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.filteredLocalizedCountriesSync = this.localizedCountries;
    }
    if (this.filteredLocalizedCountriesSync.length == 1 && event.target.value) {
      let option = this._auto.options.find((option) => {
        return (
          option.value == this.filteredLocalizedCountriesSync[0].key &&
          this.filteredLocalizedCountriesSync[0].value.toLowerCase() ===
            event.target.value.toLowerCase()
        );
      });
      if (option) {
        option?.select();
        this.field.formControl?.setValue(
          this.filteredLocalizedCountriesSync[0].key
        );
      }
    }
  }

  getFormControl(): FormControl {
    const formControl = this.field.formControl as FormControl;
    if (this.props.disabled) {
      formControl.disable();
    }
    return formControl;
  }

  displayFn(options: any): (inputValue: any) => any {
    return (inputValue: any) => {
      if (!inputValue) {
        return '';
      }
      const ret = options.find(
        (option: any) => option.key === inputValue
      )?.value;
      if (ret) {
        return ret;
      }
      return 'Erreur';
    };
  }
}
