import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/core/services/auth.service';
import { ExportService } from '@app/data/services/export.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

enum ExportDataType {
  RegisteredDevices = 'registered_devices',
  MedicalDeviceBatchs = 'medical_device_batchs',
  Users = 'users',
}

enum ExportFormat {
  Excel = 'excel',
  Csv = 'csv',
}

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrl: './export-dialog.component.scss',
})
export class ExportDialogComponent {
  exportForm = new FormGroup({});
  exportModel: {
    data: ExportDataType | undefined;
    format: ExportFormat | undefined;
    sites: string[] | undefined;
  } = {
    data: undefined,
    format: undefined,
    sites: undefined,
  };
  options: FormlyFormOptions = {
    formState: {
      cancel: false,
    },
  };
  fieldsForm: FormlyFieldConfig[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dataType: ExportDataType | undefined;
      chooseSites?: boolean;
      isAdmin?: boolean;
    },
    public dialogRef: MatDialogRef<ExportDialogComponent>,
    private exportService: ExportService,
    private authService: AuthService
  ) {
    console.log('Data:', data);
    this.fieldsForm.push({
      type: 'flex-layout',
      templateOptions: {
        fxLayout: 'column',
        fxLayoutGap: '20px',
      },
      fieldGroup: [
        {
          key: 'sites',
          type: 'select',
          // defaultValue: this.getUserCurrentSite(),
          props: {
            label: 'Sites',
            multiple: true,
            required: true,
            options: this.getUserSiteOptions(),
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              const currentSite = this.getUserCurrentSite();
              if (currentSite) {
                field.formControl?.setValue([currentSite]);
              }
            },
          },
          hide: !data.chooseSites,
        },
        {
          key: 'data',
          type: 'select',
          defaultValue: data.dataType,
          props: {
            label: "Données d'export",
            required: true,
            options: [
              { label: 'Références', value: 'registered_devices' },
              { label: 'Lots', value: 'medical_device_batchs' },
              { label: 'Utilisateurs', value: 'users' },
            ],
          },
        },
        {
          key: 'format',
          type: 'radio',
          props: {
            label: "Format d'export",
            required: true,
            options: [
              { label: 'Excel', value: 'excel' },
              { label: 'CSV', value: 'csv' },
            ],
          },
        },
      ],
    });
  }

  getUserSiteOptions() {
    const user = this.authService.userValue;
    if (!user || !user.sites) {
      return [];
    }
    return user!.sites.map((site) => {
      return { label: site.name, value: site.id };
    });
  }

  getUserCurrentSite() {
    const currentSite = this.authService.getUserCurrentSite();
    if (!currentSite || currentSite === '') {
      return undefined;
    } else {
      return currentSite;
    }
  }

  submitExportForm() {
    console.log('Submitting:', this.exportModel);
    this.exportService
      .export(
        this.exportModel.data!,
        this.exportModel.format!,
        this.exportModel.sites
      )
      .subscribe({
        next: (data) => {
          this.downloadExportedFile(data);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  private downloadExportedFile(data: any) {
    const blob = new Blob([data], {
      type: 'application/octet-stream',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.getFileName(
      this.exportModel.data!,
      this.exportModel.format!
    );
    a.click();
    window.URL.revokeObjectURL(url);
    this.dialogRef.close();
  }

  getFileName(data: ExportDataType, type: ExportFormat) {
    let ext = '';
    switch (type) {
      case 'excel':
        ext = 'xlsx';
        break;
      case 'csv':
        ext = 'csv';
        break;
      default:
        ext = '';
    }
    let fileName = '';
    switch (data) {
      case 'medical_device_batchs':
        fileName = 'lots';
        break;
      case 'registered_devices':
        fileName = 'references';
        break;
      case 'users':
        fileName = 'users';
        break;
      default:
        fileName = '';
    }
    return `${fileName}.${ext}`;
  }
}
